import * as React from "react";
import styled from "styled-components";

const Text = styled.div`
  color: #232129;
  padding: 20px;
  font-family: -apple-system, Roboto, sans-serif, serif;
`;

const IndexPage = () => {
  return (
    <Text>
      Hello! I am putting things right. Soon this will be an astonishing
      website.
    </Text>
  );
};

export default IndexPage;
